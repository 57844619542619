import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedFilterService {

  constructor() { }

  private filterSubject = new BehaviorSubject<any>(null);
  
  filterData$ = this.filterSubject.asObservable();

  
  private reloadPeriodsSubject = new Subject<void>();
  reloadPeriods$ = this.reloadPeriodsSubject.asObservable();

  private selectedPeriodSubject = new BehaviorSubject<any | null>(null);
  selectedPeriod$ = this.selectedPeriodSubject.asObservable();

  private periodsSubject = new BehaviorSubject<any[]>([]);
  periods$ = this.periodsSubject.asObservable();

  updateFilter(filterData: any) {
    this.filterSubject.next(filterData);
  }

  setPeriods(periods: any[]): void {
    this.periodsSubject.next(periods); // Actualiza la lista de periodos
  }

  getPeriods(): any[] {
    return this.periodsSubject.value; // Retorna la lista actual
  }

  setSelectedPeriod(period: any): void {
    this.selectedPeriodSubject.next(period);
  }

  getSelectedPeriod(): any | null {
    return this.selectedPeriodSubject.value;
  }

  get selectedPeriod() {
    return this.selectedPeriodSubject.value;
  }
    
  triggerPeriodsReload() {
    this.reloadPeriodsSubject.next();
  }
}
