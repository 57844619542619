<mat-action-list id="sidenav" style="position: relative;overflow-y: auto;">
    <mat-list-item>
        <h1 *ngIf="(navState| async) > 0 && (navState| async) <=7 ">Catálogos</h1>
        <h1 *ngIf="(navState| async) > 7 && (navState| async) <=13 ">Cuestionarios</h1>

        <h1 *ngIf="(navState| async) > 13 && (navState| async) <=22 ">Seguimiento</h1>

        <h1 *ngIf="(navState| async) > 22 && (navState| async) <= 26 ">Administrador</h1>
        <h1 *ngIf="(navState| async) > 26 && (navState| async) <= 28 && !isMaster">Asociado</h1>
        <h1 *ngIf="(navState| async) > 26 && (navState| async) <= 28 && isMaster">Master</h1>
        <h1 *ngIf="(navState| async) > 28 && (navState| async) <= 31">Opciones</h1>
        
        
    </mat-list-item>    
    <ng-container *ngIf="(navState| async) > 0 && (navState| async) <=7 ">
        <!-- <button mat-list-item [routerLink]="[ '/home' ]" [ngClass]="{'selected' : (navState | async ) == 1}">
            <mat-icon svgIcon="empresa" color="primary"></mat-icon>
            Empresa
        </button> -->
        <button mat-list-item [routerLink]="[ '/period' ]" [ngClass]="{'selected' : (navState | async ) == 2}">
            <mat-icon svgIcon="periodo" color="primary"></mat-icon>
            Período Evaluación
        </button>
        <button mat-list-item [routerLink]="[ '/department' ]" [ngClass]="{'selected' : (navState | async ) == 3}">
            <mat-icon svgIcon="departamento" color="primary"></mat-icon>
            Departamentos
        </button>
        <button mat-list-item [routerLink]="[ '/area' ]" [ngClass]="{'selected' : (navState | async ) == 4}">
            <mat-icon svgIcon="area" color="primary"></mat-icon>
            Áreas
        </button>
        <button mat-list-item [routerLink]="[ '/post' ]" [ngClass]="{'selected' : (navState | async ) == 5}">
            <mat-icon svgIcon="puesto" color="primary"></mat-icon>
            Puestos
        </button>
        <button mat-list-item [routerLink]="[ '/workcenter' ]" [ngClass]="{'selected' : (navState | async ) == 6}">
            <mat-icon svgIcon="centro" color="primary"></mat-icon>
            Centros de Trabajo
        </button>
        <button mat-list-item [routerLink]="[ '/employee' ]" [ngClass]="{'selected' : (navState | async ) == 7}">
            <mat-icon svgIcon="empleado" color="primary"></mat-icon>
            Empleados
        </button>
    </ng-container>

    <div class="main-buttons" *ngIf="(navState| async) > 7 && (navState | async) <= 13">
        <button mat-mini-fab [color]="(navState| async) > 7 && (navState| async) <= 9 ? 'light':'primary'"
                [routerLink]="selectedTab == 'results' ? ['/resultsats'] : ['/questionnaire', 'ats']" matTooltip="Guia I: Acontecimientos Traumáticos Severos" matTooltipPosition="after"
                matTooltipClass="custom-tooltip">
            ATS
        </button>
        <button mat-mini-fab [color]="(navState| async) > 9 && (navState| async) <= 11 ? 'light':'primary'"
                [routerLink]="selectedTab == 'results' ? ['/resultsrp'] : ['/questionnaire', 'rp']" matTooltip="Guia II: Riesgo Psicosocial" matTooltipPosition="after"
                matTooltipClass="custom-tooltip">
            RP
        </button>
        <button mat-mini-fab [color]="(navState| async) > 11 && (navState| async) <= 13 ? 'light':'primary'"
                [routerLink]="selectedTab == 'results' ? ['/resultseo'] : ['/questionnaire', 'eo']" matTooltip="Guia III: Entorno Organizacional" matTooltipPosition="after"
                matTooltipClass="custom-tooltip">
            EO
        </button>

    </div>

    <ng-container *ngIf="(navState| async) > 7 && (navState| async) <= 9 ">
        <button mat-list-item [routerLink]="[ '/questionnaire/ats' ]" [ngClass]="{'selected' : (navState | async ) == 8}" (click)="onTabClick('questionnaires')">
            <mat-icon>assignment</mat-icon>
            Cuestionarios
        </button>
        <button mat-list-item (click)="moveToResults('/resultsats')" [ngClass]="{'selected' : (navState | async ) == 9}" (click)="onTabClick('results')">
            <mat-icon> assessment</mat-icon>
            Resultados
        </button>
    </ng-container>

    <ng-container *ngIf="(navState| async) > 9 && (navState| async) <= 11">
        <button mat-list-item [routerLink]="[ '/questionnaire/rp' ]"
            [ngClass]="{'selected' : (navState | async ) == 10}" (click)="onTabClick('questionnaires')">
            <mat-icon>assignment</mat-icon>
            Cuestionarios
        </button>
        <button mat-list-item (click)="moveToResults('/resultsrp')" [ngClass]="{'selected' : (navState | async ) == 11}" (click)="onTabClick('results')">
            <mat-icon> assessment</mat-icon>
            Resultados
        </button>
    </ng-container>

    <ng-container *ngIf="(navState| async) > 11 && (navState| async) <= 13">
        <button mat-list-item [routerLink]="[ '/questionnaire/eo' ]"
            [ngClass]="{'selected' : (navState | async ) == 12}" (click)="onTabClick('questionnaires')">
            <mat-icon>assignment</mat-icon>
            Cuestionarios
        </button>
        <button mat-list-item (click)="moveToResults('/resultseo')" [ngClass]="{'selected' : (navState | async ) == 13}" (click)="onTabClick('results')">
            <mat-icon> assessment</mat-icon>
            Resultados
        </button>
    </ng-container>

    <ng-container *ngIf="(navState| async) > 13 && (navState| async) <= 22 ">
        <button mat-list-item [routerLink]="[ '/current-state' ]" [ngClass]="{'selected' : (navState | async ) == 14}">
            <mat-icon>assignment</mat-icon>
            Seguimiento Personalizado
        </button>
        <button mat-list-item [routerLink]="[ '/group-policies' ]" [ngClass]="{'selected' : (navState | async ) == 15}">
            <mat-icon>assignment</mat-icon>
            Iniciativas Grupales
        </button>
    </ng-container>

    <ng-container *ngIf="(navState| async) > 22 && (navState| async) <= 26 ">
        <button mat-list-item [routerLink]="[ '/admin-user' ]" [ngClass]="{'selected' : (navState | async ) == 23}">
            <mat-icon svgIcon="empresa" color="primary"></mat-icon>
            Empresas
        </button>
        <button mat-list-item [routerLink]="[ '/admin-masters' ]" [ngClass]="{'selected' : (navState | async ) == 24}">
            <mat-icon svgIcon="empresa" color="primary"></mat-icon>
            Masters
        </button>
        <button mat-list-item [routerLink]="[ '/admin-asociates' ]" [ngClass]="{'selected' : (navState | async ) == 25}">
            <mat-icon svgIcon="empresa" color="primary"></mat-icon>
            Asociados autorizados
        </button>
        <button mat-list-item [routerLink]="[ '/user-config' ]" [ngClass]="{'selected' : (navState | async ) == 26}">
            <mat-icon svgIcon="empresa" color="primary"></mat-icon>
            Usuarios
        </button>
    </ng-container>

    <ng-container *ngIf="(navState| async) > 26 && (navState| async) <= 28 ">
        <button mat-list-item [routerLink]="[ '/associate-company' ]" [ngClass]="{'selected' : (navState | async ) == 27}">
            <mat-icon svgIcon="empresa" color="primary"></mat-icon>
            Empresas
        </button>
        <button *ngIf="isMaster" mat-list-item [routerLink]="[ '/master-associates' ]" [ngClass]="{'selected' : (navState | async ) == 28}">
            <mat-icon svgIcon="empresa" color="primary"></mat-icon>
            Asociados autorizados
        </button>
    </ng-container>

    <ng-container *ngIf="(navState| async) > 28 && (navState| async) <= 31 ">
        <button mat-list-item [routerLink]="[ '/admin-company' ]" [ngClass]="{'selected' : (navState | async ) == 29}">
            <mat-icon svgIcon="empresa" color="primary"></mat-icon>
            Empresas
        </button>
        <button mat-list-item [routerLink]="[ '/company-users' ]" [ngClass]="{'selected' : (navState | async ) == 30}">
            <mat-icon svgIcon="empresa" color="primary"></mat-icon>
            Usuarios
        </button>
        <button mat-list-item [routerLink]="[ '/company-roles' ]" [ngClass]="{'selected' : (navState | async ) == 31}">
            <mat-icon svgIcon="empresa" color="primary"></mat-icon>
            Roles
        </button>
    </ng-container>

</mat-action-list>