<mat-toolbar class="mat-elevation-z5">
    <mat-toolbar-row style="padding-left: 0;">
        <a class="logo-container" [routerLink]="['/']" *ngIf="typeUser != 'S' && typeUser != 'T' && typeUser != 'AU'">
            <img src="./assets/images/logo2.svg" alt="LOGO">
        </a>
        <a class="logo-container" [routerLink]="['/associates-company']" *ngIf="typeUser == 'T'">
            <img src="./assets/images/logo2.svg" alt="LOGO">
        </a>
        <a class="logo-container" [routerLink]="['/admin-user']" *ngIf="typeUser == 'S'">
            <img src="./assets/images/logo2.svg" alt="LOGO">
        </a>
        <a class="logo-container" [routerLink]="['/admin-company']" *ngIf="typeUser == 'AU'">
            <img src="./assets/images/logo2.svg" alt="LOGO">
        </a>
        <div style="margin: 0;">
            <button class="small-screens" mat-icon-button color="primary" (click)="onMenu.emit()">
                <mat-icon> menu </mat-icon>
            </button>
        </div>
        <section class="title-container">
            <h1 *ngIf="(session | async)?.companyName">{{(session | async)?.companyName}}</h1>
            <h1 *ngIf="(session | async)?.associatedName">{{(session | async)?.associatedName}}</h1>
        </section>

        <div style="flex:1; margin-top: 30px;">
            
            <div *ngIf="isOnQuestionnaire">
                <input id="cb" type="text" hidden>
                <section class="options-container" style="flex-flow: row;">
                    <span class="periodo-text">Periodo: </span>
                    <div class="filters" [formGroup]="form">
                        <mat-form-field class="mat-select-navbar">
                            <mat-label *ngIf="!isLabelHidden && (!periods || periods.length === 0)"> <span class="required">*</span> Periodos</mat-label>
                            <mat-select class="navbar-period-selector-container" formControlName="period" (selectionChange)="filterData()" (openedChange)="toggleLabel($event)">
                                <mat-option class="navbar-period-selector" *ngFor="let period of periods" [value]="period._periodID">
                                    {{period._name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>      
                    </div>
                </section>
            </div>
        </div>

        <button mat-icon-button [matMenuTriggerFor]="menu" class="small-screens">
            <mat-icon>more_vert</mat-icon>
        </button>

        <mat-menu #menu="matMenu" xPosition="before">

            <ng-container *ngIf="typeUser != 'S' && typeUser != 'T' && typeUser != 'AU'">
                <div mat-menu-item [routerLink]="['/period']">
                    <button [color]="(topNavbarState | async) == 1 ? 'primary' :'accent'" mat-icon-button>
                        <mat-icon svgIcon="configuracion" color="primary"></mat-icon>
                    </button>
                    <span class="small">Catálogos</span>
                </div>

                <div mat-menu-item [routerLink]="['/questionnaire/ats']" (click)="updatePeriods()">
                    <button [color]="(topNavbarState | async) == 2 ? 'primary' :'accent'" mat-icon-button>
                        <mat-icon color="primary">assignment</mat-icon>
                    </button>
                    <span>Cuestionarios</span>
                </div>

                <!--  <div mat-menu-item [routerLink]="['/current-state']">
                    <button [color]="(topNavbarState | async) == 4 ? 'primary' :'accent'" mat-icon-button>
                        <mat-icon color="primary">today</mat-icon>
                    </button>
                    <span>Estado Actual</span>
                </div> -->

                <div mat-menu-item [routerLink]="['/current-state/']" [queryParams]="{ all: true }" (click)="updatePeriods()">
                    <button [color]="(topNavbarState | async) == 4 ? 'primary' :'accent'" mat-icon-button>
                        <mat-icon color="primary">arrow_right_alt</mat-icon>
                    </button>
                    <span>Seguimiento</span>
                </div>

                <div mat-menu-item [routerLink]="['/users/account']">
                    <button [color]="(topNavbarState | async) == 3 ? 'primary' :'accent'" mat-icon-button>
                        <mat-icon svgIcon="configuracion" color="primary"></mat-icon><!-- <mat-icon svgIcon="usuario" color="primary"></mat-icon> -->
                    </button>
                    <span>Configuración</span>
                </div>



            </ng-container>

            
            <div mat-menu-item *ngIf="typeUser == 'AU'" (click)="loginCompany()">
                <button color="primary" mat-icon-button>
                    <mat-icon color="primary">open_in_browser</mat-icon>
                </button>
                <span>Acceder</span>
            </div>

            <div mat-menu-item *ngIf="typeUser != 'S' && typeUser != 'T' && typeUser != 'AU'" (click)="loginOptions()">
                <button color="primary" mat-icon-button>
                    <mat-icon color="primary">business</mat-icon>
                </button>
                <span>Empresas</span>
            </div>
            

            <div mat-menu-item (click)="logout()">
                <button color="warn" mat-icon-button>
                    <mat-icon color="warn">exit_to_app</mat-icon>
                </button>
                <span>Salir</span>
            </div>

        </mat-menu>

        <section class="actions-container normal-screens">
            <ng-container *ngIf="typeUser != 'S' && typeUser != 'T' && typeUser != 'AU'" >
                <div>
                    <button [color]="(topNavbarState | async) == 1 ? 'primary' :'accent'" mat-icon-button
                        [routerLink]="['/period']">
                        <!-- <mat-icon svgIcon="configuracion" color="primary"></mat-icon> -->
                        <!-- <mat-icon color="primary">library_books</mat-icon> -->
                        <!-- <mat-icon color="primary">recent_actors</mat-icon> -->
                        <mat-icon color="primary">import_contacts</mat-icon>
                    </button>
                    <span class="small">Catálogos</span>
                </div>

                <div>
                    <button [color]="(topNavbarState | async) == 2 ? 'primary' :'accent'" mat-icon-button
                        [routerLink]="['/questionnaire/ats']" (click)="updatePeriods()">
                        <mat-icon color="primary"> assignment</mat-icon>
                    </button>
                    <span>Cuestionarios</span>
                </div>

                <!-- <div>
                    <button [color]="(topNavbarState | async) == 4 ? 'primary' :'accent'" mat-icon-button
                        [routerLink]="['/current-state']">
                        <mat-icon color="primary">today</mat-icon>
                    </button>
                    <span>Estado Actual</span>
                </div> -->

                <div>
                    <button [color]="(topNavbarState | async) == 4 ? 'primary' :'accent'" mat-icon-button
                        [routerLink]="['/current-state/']" [queryParams]="{ all: true }" (click)="updatePeriods()">
                        <mat-icon color="primary">arrow_right_alt</mat-icon>
                    </button>
                    <span>Seguimiento</span>
                </div>

                <div>
                    <button [color]="(topNavbarState | async) == 3 ? 'primary' :'accent'" mat-icon-button
                        [routerLink]="['/users/account']">
                        <mat-icon svgIcon="configuracion" color="primary"></mat-icon><!-- <mat-icon svgIcon="usuario" color="primary"></mat-icon> -->
                    </button>
                    <span>Configuración</span>
                </div>

            </ng-container>

            <div *ngIf="typeUser == 'AU'">
                <button color="primary" mat-icon-button (click)="loginCompany()">
                    <mat-icon>open_in_browser</mat-icon>
                </button>
                <span>Acceder</span>
            </div>

            <div *ngIf="typeUser != 'S' && typeUser != 'T' && typeUser != 'AU'">
                <button color="primary" mat-icon-button (click)="loginOptions()">
                    <mat-icon>business</mat-icon>
                </button>
                <span>Empresas</span>
            </div>

            <div>
                <button color="warn" mat-icon-button (click)="logout()">
                    <mat-icon>exit_to_app</mat-icon>
                </button>
                <span>Salir</span>
            </div>
        </section>

    </mat-toolbar-row>
</mat-toolbar>