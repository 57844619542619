import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import * as jwt_decode from 'jwt-decode';
import { Observable, Subscription } from 'rxjs';
import { Session } from 'src/app/_shared/_models/session.model';
import { LicenseService } from 'src/app/_shared/_services/license.service';
import { LoaderService } from 'src/app/_shared/_services/loader.service';
import { SessionService } from 'src/app/_shared/_services/session.service';
import { FormDialogComponent } from 'src/app/_shared/_components/form-dialog/form-dialog.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { CompanyService } from 'src/app/settings/company/company.service';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { Questionnaire, QuestionnaireEmployeesFilters } from 'src/app/_shared/_models/questionnaire.model';
import { TableComponent } from 'src/app/_shared/table/table.component';
import { QuestionnaireService } from 'src/app/_shared/_services/questionnaire.service';
import { PagingParams } from 'src/app/_shared/_models/paging.model';
import { OrderBy } from 'src/app/_shared/_models/table.model';
import { PeriodService } from 'src/app/settings/period/period.service';
import { filter } from 'rxjs/operators';
import { SharedFilterService } from 'src/app/_shared/_services/shared-filter.service';



@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
    
    isLabelHidden: boolean = false;

    isOnQuestionnaire : boolean = false;

    form: UntypedFormGroup = new UntypedFormGroup({
        period: new UntypedFormControl(),
        workCenter: new UntypedFormControl(0),
        department: new UntypedFormControl(0),
        area: new UntypedFormControl(0),
        post: new UntypedFormControl(0),
        survey: new UntypedFormControl()
    })

    filters = new QuestionnaireEmployeesFilters()

    paging: PagingParams = new PagingParams();

    periods = []

    @ViewChild(TableComponent) tableComponent: TableComponent;

    orderBy: OrderBy[] = []

    rows = []

    selectedPeriod: any;

    toggleLabel(isOpen: boolean): void {
        if (!this.form.get('period')?.value) {
          this.isLabelHidden = isOpen;
        }
      }

    filterData() {
        const selectedPeriod = this.form.get('period').value;
        const periods = this.sharedFilterService.getPeriods();
        const period = periods.find((p) => p._periodID === selectedPeriod);
        this.sharedFilterService.setSelectedPeriod(period);
        this.sharedFilterService.updateFilter({ period: selectedPeriod });
    }

    typeUser

    @Output()
    onMenu: EventEmitter<any> = new EventEmitter<any>();

    session: Observable<Session>

    topNavbarState: Observable<number>

    smallScreen: Observable<boolean>

    private reloadSubscription: Subscription;

    constructor(
        private sessionService: SessionService,
        private route: Router,
        private loaderService: LoaderService,
        private licenseService: LicenseService,
        private dialog: MatDialog,
        private router: Router,
        private companyService: CompanyService,
        protected questionnaireService: QuestionnaireService,
        protected cdr: ChangeDetectorRef,
        private periodService: PeriodService,
        private sharedFilterService: SharedFilterService

    ) {
        this.session = this.sessionService._session;
        //S = superuser
        this.session.subscribe((s) => {
            if (s) {
                this.typeUser = this.decodeToken(s.token).LicenseType;
                if (this.decodeToken(s.token).AdminOptions == 'True' && this.typeUser != 'S') {
                    this.typeUser = 'AU';
                }
            }
        })
        this.getSessionInfo()
    }

    async getSessionInfo() {
        var s = this.sessionService.getSession();
        if (s && s.type == "A") {
            let res = await this.licenseService.getLicense().toPromise()
            this.licenseService.setLicenseState(res.finishLicenseDate, res.associatedID);
            this.licenseService.setLicense(res);
        }
        this.topNavbarState = this.sessionService._topNavbarState;
    }

    ngOnInit(): void {
        // Suscribirse a los eventos de navegación
        this.router.events
        .pipe(filter(event => event instanceof NavigationEnd)) // Filtrar solo eventos de tipo NavigationEnd
        .subscribe((event: NavigationEnd) => {
            const currentUrl = event.urlAfterRedirects; // Obtiene la URL actual después de redirecciones
            //console.log('Cambio detectado en la URL:', currentUrl);

            if (currentUrl.includes('questionnaire') || currentUrl.includes('results') || currentUrl.includes('current-state') || currentUrl.includes('group-policies')) {
            //console.log('La URL contiene "questionnaire"');
            this.isOnQuestionnaire = true;
            } else {
                this.isOnQuestionnaire = false;
            }
        });

        const onReloadCurrentUrl = this.router.url; // Obtiene la URL completa actual
        //console.log('URL actual:', currentUrl);

        if (onReloadCurrentUrl.includes('questionnaire') || onReloadCurrentUrl.includes('results') || onReloadCurrentUrl.includes('current-state') || onReloadCurrentUrl.includes('group-policies')) {
        //console.log('La URL contiene "questionnaire"');
        this.isOnQuestionnaire = true;
        } else {
            this.isOnQuestionnaire = false;
        }

        // Escuchar solicitudes de recarga de periodos
        this.reloadSubscription = this.sharedFilterService.reloadPeriods$
        .subscribe(() => {
            //this.updatePeriods();
            this.getAll();
        });

        this.getAll();

        this.route.events.subscribe(event => {
            if (event instanceof NavigationEnd && this.route.url === '/home') {
                //this.getAll(); // Se ejecuta al regresar a esta ruta
            }
            });
        
        /*this.sharedFilterService.reloadPeriods$.subscribe(() => {
            this.getAll(); // Ejecuta nuevamente la lógica
        });*/
        
        
        /*this.sharedFilterService.selectedPeriod$.subscribe((period) => {
            if (period) {
                this.selectedPeriod = period;
                console.log('Periodo seleccionado:', this.selectedPeriod);
                //this.form.get('period').setValue(period._periodID);
            } else {
                //this.form.get('period').setValue(this.periods[this.periods.length - 1]._periodID);
                //this.sharedFilterService.updateFilter(this.form.get('period').value);
            }
        });*/

    }
    

    getAll() {

        return Promise.all(
            [
                this.periodService.getAllSelect().toPromise(),
            ]
        )
            .then((result) => {

                //console.log(result);
                this.periods = result[0].items;
                
                // Enviar la lista de periodos al servicio
                this.sharedFilterService.setPeriods(this.periods);

                if (this.periods.length > 0) { //Get the last one
                    /*this.sharedFilterService.filterData$.subscribe(filterData => {
                        if (filterData && filterData.period) {
                          this.form.get('period').setValue(filterData.period);
                        } else {
                            this.form.get('period').setValue(this.periods[this.periods.length - 1]._periodID);
                        }
                      });*/


                      this.sharedFilterService.selectedPeriod$.subscribe((period) => {
                        if (period) {
                            this.selectedPeriod = period;
                            //console.log('Periodo seleccionado:', this.selectedPeriod);
                            this.form.get('period').setValue(period._periodID);
                        } else {
                            this.form.get('period').setValue(this.periods[this.periods.length - 1]._periodID);
                            //this.sharedFilterService.updateFilter(this.form.get('period').value);
                        }
                        });


                } 
                else {
                    //this.sendToPeriodForm();
                }
                
                return this.filterData();

            })
    }

    updatePeriods(){
        this.filterData();
        const period = this.sharedFilterService.selectedPeriod;
        if (period) {
            this.selectedPeriod = period;
            //console.log('Periodo seleccionado:', this.selectedPeriod);
            this.form.get('period').setValue(period._periodID);
        } else {
            this.form.get('period').setValue(this.periods[this.periods.length - 1]._periodID);
            this.sharedFilterService.updateFilter(this.form.get('period').value);
        }
    }

    reloadPeriodList() {
       this.router.navigateByUrl('/home', { skipLocationChange: true }).then(() => {
            this.router.navigate([this.router.url]);
          });

        this.sharedFilterService.triggerPeriodsReload();
        this.filterData();
        //console.log(this.periods);

      }

    logout() {
        this.loaderService.show();
        this.licenseService.setLicenseState(-1);
        this.sessionService.logout();

        if (this.typeUser == 'S')
            this.route.navigate(['/users/root']);
        else if (this.typeUser == 'T')
            this.route.navigate(['/users/associates']);
        else
            this.route.navigate(['/users/login']);
        this.loaderService.hide();

    }

    async loginCompany() {

        var token = this.sessionService.getSession().token;
        //var companies: any = (await this.companyService.getAll().toPromise()).items

        var info = this.sessionService.decodeAndReturn(token);
        var companies : any = JSON.parse(info.Companies);

        var sessionData = {
            name: "Inicio de Sesión",
            message: "Seleccione la empresa deseada: ",
            messageStyles: {
                'margin': '0'
            },
            loginOptions: [
                //...companies.map(c => { return { placeholder: c._businessName, value: c._companyID, type: 'primary' } }),
                ...companies.map(c => { return { placeholder : c.Name, value: c.CompanyID, type: 'primary' } }),
            ]
        }

        this.loaderService.hide();
        var company = await this.dialog.open(FormDialogComponent, { data: sessionData, panelClass: 'panel-nooverflow' }).afterClosed().toPromise()
        this.loaderService.show();

        if (!company) {
            this.loaderService.hide()
            return
        }

        var ses = await this.sessionService.loginCompany(company, token).toPromise()

        //Insert session data
        this.sessionService.login(ses[1].token, ses[0].token);

        await this.getSessionInfo()

        this.getAll();
        
        this.router.navigate(['/home'])

        this.loaderService.hide()

        //this.reloadPeriodList()
    }

    async loginOptions() {

        var token = this.sessionService.getAdminToken();
        /* var session = this.sessionService.getSession();
        var isAdmin = session.isAdmin == 'true'; */

        var adminSession = this.sessionService.decodeAndReturn(token);
        var isAdmin = adminSession.AdminOptions == 'True';

        var info = this.sessionService.decodeAndReturn(token);
        var companies : any = JSON.parse(info.Companies);

        var sessionData = {
            name: "Cambiar de Empresa",
            message: "Seleccione la empresa deseada: ",
            messageStyles: {
                'margin': '0'
            },
            loginOptions: [
                ...companies.map(c => { return { placeholder : c.Name, value: c.CompanyID, type: 'primary'} } ),
            ],
        }

        if(isAdmin){
            sessionData['adminOption'] = true
        }
        
        this.loaderService.hide();
        var company = await this.dialog.open(FormDialogComponent, { data: sessionData, panelClass: 'panel-nooverflow' }).afterClosed().toPromise()
        this.loaderService.show();

        if (!company) {
            this.loaderService.hide()
            return
        }else if(company != -1)
        {
            var ses = await this.sessionService.loginCompany(company, token).toPromise()

            //Insert session data
            this.sessionService.login(ses[1].token, ses[0].token); 
            await this.getSessionInfo()
            this.router.routeReuseStrategy.shouldReuseRoute = () => false;
            this.getAll();
            this.router.navigate(['/home'])
            //this.reloadPeriodList()
        }
        else{
            this.licenseService.setLicenseState(-1);
            this.licenseService.setLicense(null);

            this.sessionService.login(token);
            this.router.navigate(['/admin-company'])
        }

        this.loaderService.hide()
    }

    decodeToken(token: string) {
        var decoded: any = jwt_decode(token);
        if (!decoded)
            return null;
        return decoded;
    }
}
