import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NavigationStateService {
    private selectedTab: string = 'questionnaires'; // Valor predeterminado

    setSelectedTab(tab: string) {
        this.selectedTab = tab;
    }

    getSelectedTab(): string {
        return this.selectedTab;
    }
}
