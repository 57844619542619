import { UntypedFormGroup } from '@angular/forms';

export class FormData {
    name: string;
    nameCentered? : boolean;

    subtitle: string;
    message: any;
    group?: UntypedFormGroup;
    selectActions?: any;
    inputActions? : any;
    helpActions? : any;

    noCancel?: boolean;
    isAlertMessage?: boolean;
    putEvaluathecLogo?: boolean;
    sendWhatsapp?: boolean;
    isAlertMessageWithName?: boolean;
    hasExcelFormatErrors?: boolean;
    hasLicenseErrors?: boolean;
    sendMailAlertMessage? : boolean;
    showManualButton?: boolean;
    sendToPeriodFormButton?: boolean;
    url?: string;
    noButtonCancel? : boolean;
    noSave?: boolean;
    noAccept?: any;
    closeWindow : boolean;
    
    checkValues?: any;
    checkFunction?: any;


    errors? : any[]
    action? : any;
    templateTitle? : string;
    messageStyles? : any = null;
    adminOption? : boolean = false;

    buttonOptions? : any[];
    loginOptions? : any[];
    extraMessages? : any[]

    statusCode? : any[];

    isSmall? : boolean;
    isBig? : boolean;

    file? : any;
    fileAsHtml? : any;
    fileName? : string;

    noButtons? : boolean;

    multipleMessages? : any[];

    blockEnter? : boolean;


}