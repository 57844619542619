import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ChangeDetectorRef, ElementRef, ViewChild, Directive } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { map } from 'rxjs/operators';
import { TableComponent } from '../table/table.component';
import { FormDialogComponent } from '../_components/form-dialog/form-dialog.component';
import { PagingParams } from '../_models/paging.model';
import { LoaderService } from '../_services/loader.service';
import { OrderBy } from '../_models/table.model';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { HttpInterceptorService } from '../_services/http.interceptor';
import { ErrorStatusService } from '../_services/error-status.service';
import { SharedFilterService } from '../_services/shared-filter.service';
import { Router } from '@angular/router';


export class ServiceBase<TClass, TIncludes, TFilters> {

    url: string;

    protected http: HttpClient;

    constructor(
        protected TCreator: { new(): TClass; },
    ) {
    }

    get(id: number, includes: TIncludes) {
        return this.http.get(this.url + '/' + id, {
            params: {
                ...includes as any
            }
        });
    }

    getAll(paging?: PagingParams, filters?: TFilters, includes?: TIncludes, orderBy?: OrderBy[], token?: string) {

        return this.http.get(this.url, {
            //observe: 'response',
            params: {
                ...paging as any,
                ...filters as any,
                ...includes as any,
                orderBy: (orderBy && orderBy.length != 0) ? JSON.stringify(orderBy) : ''
            },
            headers: new HttpHeaders({ 'Authorization': 'Bearer ' + token })
        }).pipe(
            map((x: any) => {
                x.items = x.items.map(i => Object.assign(new this.TCreator(), i));
                return x;
            })
        )
    }

    getAllSelect(paging?: PagingParams, filters?: TFilters, includes?: TIncludes, orderBy?: OrderBy[], token?: string) {

        return this.http.get(this.url + '/s', {
            //observe: 'response',
            params: {
                ...paging as any,
                ...filters as any,
                ...includes as any,
                orderBy: (orderBy && orderBy.length != 0) ? JSON.stringify(orderBy) : ''
            },
            headers: new HttpHeaders({ 'Authorization': 'Bearer ' + token })
        }).pipe(
            map((x: any) => {
                x.items = x.items.map(i => Object.assign(new this.TCreator(), i));
                return x;
            })
        )
    }


    create(item: TClass) {
        return this.http.post(this.url, item);
    }

    delete(id: number) {
        return this.http.delete(this.url + '/' + id);
    }

    update(id: number, item: TClass) {
        return this.http.put(this.url + '/' + id, item);
    }

    defaultConfig() {
        return this.http.get(this.url + '/default', {observe: 'response'});
    }

    
}
@Directive()
export class FormManagerComponent<TClass, TIncludes, TFilters> {

    protected loaderService: LoaderService = null;

    protected snackBar: MatSnackBar = null;

    protected cdr: ChangeDetectorRef = null;

    protected dialog: MatDialog = null;

    public data: TClass[] = [];

    public paging: PagingParams = new PagingParams();

    public propertyID: string = '';

    public dialogRef?: MatDialogRef<any>;

    public orderBy: OrderBy[] = [];

    private router: Router;
    
    //private sharedFilterService: SharedFilterService;

    //public filterBy:FilterBy[] = [];

    afterOpenUpdate = null;

    beforeOpenUpdate = null;

    beforeOpenCreate = null;

    beforeUpdate = null;

    beforeCreate = null;

    beforeDelete = null;

    beforeOpenDelete = null;

    checkBeforeUpdate = null;

    checkBeforeCreate = null;

    onCreateError = null;

    onUpdateError = null;

    onDeleteError = null;

    selectActions = {};

    inputActions = {};

    helpActions = {};

    isSmall: boolean = null;

    isBig: boolean = null;

    //
    @ViewChild(TableComponent) tableComponent: TableComponent;

    public filters: any = {};

    public includes: any = {};

    //
    // ─── EVENT MESSAGES ─────────────────────────────────────────────────────────────
    //

    deleteDialogTitle = 'Eliminar registro'

    onDeleteMessage = "¿Está seguro que desea eliminar el registro?";


    deleteMassDialogTitle = 'Eliminar registros'

    onDeleteMassMessage = "¿Está seguro que desea eliminar los registros seleccionados?"

    onDeleteMassNoSelection = 'Selecciona al menos un registro para eliminar'


    onDeleteSuccessMessage = "Eliminación completada correctamente";

    onDeleteFailedMessage = "No se pudo eliminar el registro: revise sus catálogos";

    onDeleteAbortedMessage = "No se puede eliminar el registro: podría causar errores en el sistema"


    onDialogCancel = '¿Está seguro que deseas descartar los cambios?';


    editDialogTitle = 'Editar registro';

    onEditSuccessMessage = 'Cambios completados correctamente';

    onEditFailedMessage = 'Ocurrio un error al editar';


    createDialogTitle = 'Crear registro';

    onCreateSuccessMessage = "Registro creado exitosamente";

    onCreateFaileddMessage = 'Ocurrio un error al crear';


    onLoadDataFailed = 'Error al cargar los registros'

    @ViewChild('template') templateRef: ElementRef;

    constructor(
        protected TCreator: { new(): TClass; },
        protected dataService: ServiceBase<TClass, TIncludes, TFilters>,
        protected sharedFilterService: SharedFilterService,
        protected errorService?: ErrorStatusService,
    ) {
        //this.propertyID
        this.sharedFilterService = sharedFilterService;
    }

    form: UntypedFormGroup = new UntypedFormGroup({
        period: new UntypedFormControl(),
        workCenter: new UntypedFormControl(0),
        department: new UntypedFormControl(0),
        area: new UntypedFormControl(0),
        post: new UntypedFormControl(0),
        survey: new UntypedFormControl()
    })

    getPeriodListNavbar() {
        const selectedPeriod = this.form.get('period').value;
        const periods = this.sharedFilterService.getPeriods();
        const period = periods.find((p) => p._periodID === selectedPeriod);
        this.sharedFilterService.setSelectedPeriod(period);
        this.sharedFilterService.updateFilter({ period: selectedPeriod });
    }

    loadData() {
        this.loaderService.show();

        //Remaining items
        this.dataService.getAll(this.paging, this.filters, this.includes, this.orderBy)
            .toPromise()
            .then((res: any) => {
                this.data = Object.assign([], res.items);
                if (!this.data || this.data.length == 0)
                this.snackBar.open('No tienes registros en esta sección')
                this.paging = res.paging;
                this.sharedFilterService.triggerPeriodsReload();
            })
            .catch(err => {
                
                if (err.error.code != 420) {
                    this.snackBar.open(this.onLoadDataFailed);
                }
            })
            .finally(() => {
                this.loaderService.hide();
                this.cdr.markForCheck();
            });
    }


    onCreate = async () => {
        var item = new this.TCreator();

        var createdCorrectly = true;

        do {
            this.loaderService.show();

            if (this.beforeOpenCreate) {
                var res = await this.beforeOpenCreate(item);
                var hideTemplate = item['hideTemplate'];
            }

            item = (item as any).initNewForm(item)

            if (!createdCorrectly) {
                for (const key of Object.keys(item['controls'])) {
                    var control = item['controls'][key] as UntypedFormControl;
                    if (control && control.invalid) {
                        control.markAsDirty();
                        control.markAsTouched();
                    }
                }
            }

            createdCorrectly = true;

            var data = {
                name: this.createDialogTitle,
                message: '',
                group: item,
                template: (hideTemplate) ? null : this.templateRef,
                selectActions: this.selectActions,
                inputActions: this.inputActions,
                helpActions: this.helpActions,
                isSmall: this.isSmall,

            }

            this.loaderService.hide();

            var height = (!this.isBig) ? 'auto' : '600px';

            const dialogRef = this.dialog.open(
                FormDialogComponent,
                {
                    data: data,
                    panelClass: 'panel-nooverflow',
                    height: height,
                }
            );




            try {
                var dialogRes = await dialogRef.afterClosed().toPromise();
                if (!dialogRes)
                    return;

                this.loaderService.show();

                var formRes: TClass = dialogRes.getRawValue();
                if (this.beforeCreate) {
                    this.loaderService.hide()
                    var action = await this.beforeCreate(formRes);
                    this.loaderService.show()
                }

                if (this.checkBeforeCreate) {
                    var checkedValues = await this.checkBeforeCreate(formRes)
                    if (checkedValues)
                        formRes = checkedValues;
                }

                /* Object.keys(formRes).map(k => {
                    if(formRes[k] == "")
                    {
                        formRes[k] = null;
                    }
                }) */

                if (!action || !action.notYet) {

                    var updateRes = await this.dataService.create(formRes).toPromise();

                    var succesData = {
                        name: this.createDialogTitle,
                        message: this.onCreateSuccessMessage,
                        group: null,
                        template: null,
                        noCancel: true
                    }
                    this.loaderService.hide();
                    const sRef = this.dialog.open(
                        FormDialogComponent,
                        {
                            data: succesData,
                        }
                    );

                    await sRef.afterClosed().toPromise()
                    this.loaderService.show();            
                    this.loadData();
                } else {
                    createdCorrectly = false;
                    item = Object.assign(new this.TCreator(), formRes);
                }
            }
            catch (err) {

                var error = this.errorService.getError()
                if (!error) {
                    if (this.onCreateError) {
                        this.loaderService.hide();
                        var result = await this.onCreateError(formRes, err.error.code)
                        item = Object.assign(new this.TCreator(), formRes);
                        createdCorrectly = false;
                        this.loaderService.show();
                    }
                    else {
                        var message = this.onCreateFaileddMessage;
                        if (err.error && err.error.code == 420) {
                            var message = 'Renueva tu licencia para poder realizar esta acción.';
                        }
                        var errorData = {
                            name: "Error",
                            message: message,
                            group: null,
                            template: null,
                            noCancel: true,
                            selectActions: this.selectActions,
                            helpActions: this.helpActions,
                            inputActions: this.inputActions,

                        }
                        this.loaderService.hide()
                        const eRef = this.dialog.open(
                            FormDialogComponent,
                            {
                                data: errorData,
                            }
                        );

                        await eRef.afterClosed().toPromise()

                        this.loaderService.show()
                    }
                }


            }
            finally {
                this.loaderService.hide();
                this.cdr.markForCheck();
                this.sharedFilterService.triggerPeriodsReload();
            }
        } while (!createdCorrectly)

    }

    onEdit = async (item: TClass) => {

        var updatedCorrectly = true;

        var id = item[this.propertyID].value;

        var itemForm

        do {
            this.loaderService.show();

            if (this.beforeOpenUpdate) {
                var res = await this.beforeOpenUpdate(item);
            }

            itemForm = (item as any).initEditForm(item)

            if (!updatedCorrectly) {
                for (const key of Object.keys(itemForm['controls'])) {
                    var control = itemForm['controls'][key] as UntypedFormControl;
                    if (control && control.invalid) {
                        control.markAsDirty();
                        control.markAsTouched();
                    }
                }
            }

            updatedCorrectly = true;

            var data = {
                name: this.editDialogTitle,
                message: '',
                group: itemForm,
                template: this.templateRef,
                selectActions: this.selectActions,
                helpActions: this.helpActions,
                inputActions: this.inputActions,
                isSmall: this.isSmall
            }

            this.loaderService.hide();

            var height = (!this.isBig) ? 'auto' : '600px';

            const dialogRef = this.dialog.open(
                FormDialogComponent,
                {
                    data: data,
                    panelClass: 'panel-nooverflow',
                    height: height,
                }
            );


            if (this.afterOpenUpdate) {
                var wait = await dialogRef.afterOpened().toPromise();
                var res = await this.afterOpenUpdate(item);
            }

            try {
                var dialogRes = await dialogRef.afterClosed().toPromise();

                if (!dialogRes)
                    return;

                this.loaderService.show();

                var formRes: TClass = dialogRes.getRawValue();
                formRes[this.propertyID] = id


                if (this.beforeUpdate) {
                    this.loaderService.hide()
                    var action = await this.beforeUpdate(formRes);
                    this.loaderService.show()
                }

                /* Object.keys(formRes).map(k => {
                    if(formRes[k] === "")
                    {
                        formRes[k] = null;
                    }
                }) */

                if (this.checkBeforeUpdate) {
                    var checkedValues = await this.checkBeforeUpdate(formRes)
                    if (checkedValues)
                        formRes = checkedValues;
                }

                if (!action || !action.notYet) {
                    var updateRes = await this.dataService.update(id, formRes).toPromise();

                    var succesData = {
                        name: this.editDialogTitle,
                        message: this.onEditSuccessMessage,
                        group: null,
                        template: null,
                        noCancel: true
                    }
                    this.loaderService.hide();
                    const sRef = this.dialog.open(
                        FormDialogComponent,
                        {
                            data: succesData,
                        }
                    );

                    await sRef.afterClosed().toPromise()
                    this.loaderService.show();
                    this.loadData();
                } else {
                    updatedCorrectly = false;
                }
            }
            catch (err) {

                var error = this.errorService.getError()
                if (!error) {
                    if (this.onUpdateError) {
                        this.loaderService.hide();
                        var notFound = await this.onUpdateError(formRes, err.error.code)
                        item = Object.assign(new this.TCreator(), formRes);
                        this.loaderService.show();
                        if(!notFound){
                            updatedCorrectly = false;
                        }
                        else{
                            updatedCorrectly = true;
                            this.loadData()
                        }
                    }
                    else {
                        var message = this.onEditFailedMessage;
                        if (err.error && err.error.code == 420) {
                            var message = 'Renueva tu licencia para poder realizar esta acción.';
                        }
                        var errorData = {
                            name: "Error",
                            message: message,
                            group: null,
                            template: null,
                            noCancel: true,
                            selectActions: this.selectActions,
                            helpActions: this.helpActions,
                        }
                        this.loaderService.hide()
                        const eRef = this.dialog.open(
                            FormDialogComponent,
                            {
                                data: errorData,
                            }
                        );
                        await eRef.afterClosed().toPromise()
                        this.loaderService.show()
                    }
                }
            }
            finally {
                this.loaderService.hide();
                this.cdr.markForCheck();
                //console.log(this.sharedFilterService);
                this.sharedFilterService.triggerPeriodsReload();
            }

        } while (!updatedCorrectly)

    }

    onDelete = async (item: TClass) => {

        if (this.beforeDelete) {
            var abort = await this.beforeDelete(item);
            if (abort) {
                var aborted = {
                    name: 'Eliminación cancelada',
                    message: this.onDeleteAbortedMessage,
                    group: null,
                    noCancel: true,
                    template: null
                }

                const dialogRef = this.dialog.open(
                    FormDialogComponent,
                    {
                        data: aborted,
                    }
                );
                return;
            }
        }

        var data = {
            name: this.deleteDialogTitle,
            message: this.onDeleteMessage,
            group: null,
            template: this.templateRef
        }

        const dialogRef = this.dialog.open(
            FormDialogComponent,
            {
                data: data,
                panelClass: 'panel-nooverflow',
            }
        );

        try {
            var dialogRes = await dialogRef.afterClosed().toPromise();

            if (dialogRes) {
                this.loaderService.show();

                var res = await this.dataService
                    .delete(item[this.propertyID].value)
                    .toPromise()


                var succesData = {
                    name: this.deleteDialogTitle,
                    message: this.onDeleteSuccessMessage,
                    group: null,
                    template: null,
                    noCancel: true
                }
                this.loaderService.hide();
                const sRef = this.dialog.open(
                    FormDialogComponent,
                    {
                        data: succesData,
                    }
                );

                await sRef.afterClosed().toPromise()
                this.loaderService.show();             
                this.loadData();
            }
        }
        catch (err) {
            var error = this.errorService.getError()
            if (!error) {
                let notFound = false;
                var message = this.onDeleteFailedMessage;
                if (err.error && err.error.code == 420) {
                    message = 'Renueva tu licencia para poder realizar esta acción.';
                }
                if(this.onDeleteError){
                    message = this.onDeleteError(err.error.code)
                    notFound = true
                }
                this.loaderService.hide();
                var errorData = {
                    name: "Error",
                    message: message,
                    group: null,
                    template: null,
                    noCancel: true,
                    selectActions: this.selectActions,
                    helpActions: this.helpActions,

                }
                const eRef = this.dialog.open(
                    FormDialogComponent,
                    {
                        data: errorData,
                    }
                );

                await eRef.afterClosed().toPromise()
                this.loaderService.show()
                
                if(notFound)
                    this.loadData();
            }
        }
        finally {
            this.loaderService.hide();
            this.cdr.markForCheck();
            this.sharedFilterService.triggerPeriodsReload();
        }
    }

    onMassDelete = async () => {

        if (this.beforeOpenDelete) {
            var elementsToDelete = await this.beforeOpenDelete()
            if (elementsToDelete.length == 0) {

                var noSelectionData = {
                    name: this.deleteMassDialogTitle,
                    message: this.onDeleteMassNoSelection,
                    group: null,
                    template: null,
                    noCancel: true
                }
                const noSRef = this.dialog.open(
                    FormDialogComponent,
                    {
                        data: noSelectionData,
                    }
                );

                await noSRef.afterClosed().toPromise()

                this.tableComponent.cleanChecks()
                this.loaderService.hide();
                this.cdr.markForCheck();
                return;
            }
        }
        var data = {
            name: this.deleteMassDialogTitle,
            message: { 
                message: this.onDeleteMassMessage, 
                info: elementsToDelete, 
                action: 'eliminaran',},
            group: null,
        }

        const dialogRef = this.dialog.open(
            FormDialogComponent,
            {
                data: data,
                panelClass: 'panel-nooverflow',
            }
        );

        try {
            var dialogRes = await dialogRef.afterClosed().toPromise();
            var noDeleted: number = 0;
            var deleted: number = 0;
            var error

            if (dialogRes) {
                this.loaderService.show();
                for (let i = 0; i < elementsToDelete.length; i++) {
                    try {
                        var res = await this.dataService.delete(elementsToDelete[i])
                            .toPromise()
                        deleted += 1;
                    }
                    catch (ex) {
                        if (ex.error)
                            error = ex.error.code;

                        noDeleted += 1;
                    }
                }

                this.loaderService.hide();
                var deletedDialog = {
                    name: 'Resultados de la Eliminación',
                    message: "Eliminados (" + deleted + ") , No Eliminados (" + noDeleted + ") " + ((noDeleted != 0) ? (" : " + ((error == 420) ? 'Renueva tu licencia para poder realizar esta acción.' : this.onDeleteAbortedMessage)) : ""),
                    group: null,
                    noCancel: true,
                    template: this.templateRef
                }

                const dialogRef = this.dialog.open(
                    FormDialogComponent,
                    {
                        data: deletedDialog,
                    }
                );


                var dialogRes = await dialogRef.afterClosed().toPromise();

                this.loadData();
            }
        }
        catch (err) {

            var error = this.errorService.getError()
            if (!error) {

                if (err.error && err.error.code == 420) {
                    var message = 'No puede realizar operaciones hasta que actualice su licencia';
                }
                var errorData = {
                    name: "Error",
                    message: message,
                    group: null,
                    template: null,
                    noCancel: true,
                    selectActions: this.selectActions,
                    helpActions: this.helpActions,

                }
                this.loaderService.hide()
                const eRef = this.dialog.open(
                    FormDialogComponent,
                    {
                        data: errorData,
                    }
                );

                await eRef.afterClosed().toPromise()
                this.loaderService.show()
            }
        }
        finally {
            this.tableComponent.cleanChecks()
            this.loaderService.hide();
            this.cdr.markForCheck();
            this.sharedFilterService.triggerPeriodsReload();
        }
    }

    closeDialog() {
        if (!this.dialogRef) {
            return;
        }

        this.dialogRef.close();
    }
}