import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';
import { SessionService } from 'src/app/_shared/_services/session.service';
import { environment } from 'src/environments/environment';
import { LicenseService } from 'src/app/_shared/_services/license.service';
import { Router } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { FormDialogComponent } from 'src/app/_shared/_components/form-dialog/form-dialog.component';
import { take } from 'rxjs/operators';
import { NavigationStateService } from 'src/app/_shared/_services/navigation-state.service';

@Component({
    selector: 'app-sidenav',
    templateUrl: './sidenav.component.html',
    styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit {
    selectedTab: string;
    isResultsSelected: boolean = false; // Para rastrear si "results" fue seleccionado previamente

    @Output()
    onMenu: EventEmitter<any> = new EventEmitter<any>();

    navState: Observable<number>;

    version: string = "";
    
    licenseCaducated: Observable<number>

    isMaster = false;

    constructor(
        private sessionService: SessionService,
        private licenseService : LicenseService,
        private router : Router,
        private dialog : MatDialog,
        private navigationStateService: NavigationStateService

    ) {
        this.version = environment.version;
        this.navState = this.sessionService._navbarState;
        this.licenseCaducated = this.licenseService._licenseCaducated;
        
        var session = this.sessionService.getSession();
        this.isMaster = (this.sessionService.decodeAndReturn(session.token).AssociatedLevel == 0) ? true : false;

    }   

    ngOnInit(){
        var sidenav = document.getElementById('sidenav');
        sidenav.classList.add('close');

        // Recuperar el estado de la pestaña seleccionada
        this.selectedTab = this.navigationStateService.getSelectedTab();
    }

    onTabClick(tab: string): void {
        this.selectedTab = tab;
        this.navigationStateService.setSelectedTab(tab);
        // console.log("Tipo de cuestionario: " + this.navigationStateService.getSelectedTab());
    }


    shouldMoveToResults(tab: string): boolean {
        return this.isResultsSelected;
    }

    selectResults(): void {
        this.isResultsSelected = true;
        console.log('Modo de resultados activado.');
    }

    moveToResults(route : string){
        var subs = this.licenseCaducated.pipe(take(1)).subscribe(value => {
            if(value == 0)
            {
                var restrictData = {
                    name : "Licencia caducada", 
                    message : "Renueve su licencia para recuperar el acceso a los Resultados"
                }
                this.dialog.open(FormDialogComponent, {data : restrictData, panelClass: "panel-nooverflow"})
            }else{
                this.router.navigate([route]);
            }
        })
    }

    
}
