import { ChangeDetectorRef, Component, ViewChild, Injector, SecurityContext, ElementRef } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { saveAs } from 'file-saver';
import * as JSZip from 'jszip';
import { PDFDocument } from 'pdf-lib';
import { AreaService } from 'src/app/settings/area/area.service';
import { CompanyService } from 'src/app/settings/company/company.service';
import { DepartmentService } from 'src/app/settings/department/department.service';
import { PeriodService } from 'src/app/settings/period/period.service';
import { WorkCenterService } from 'src/app/settings/workcenter/workcenter.service';
import { TableComponent } from 'src/app/_shared/table/table.component';
import { FormDialogComponent } from 'src/app/_shared/_components/form-dialog/form-dialog.component';
import { PagingParams } from 'src/app/_shared/_models/paging.model';
import { Questionnaire, QuestionnaireEmployeesFilters, QuestionnaireToSend } from 'src/app/_shared/_models/questionnaire.model';
import { OrderBy, TableConfig } from 'src/app/_shared/_models/table.model';
import { LoaderService } from 'src/app/_shared/_services/loader.service';
import { QuestionnaireService } from 'src/app/_shared/_services/questionnaire.service';
import { SessionService } from 'src/app/_shared/_services/session.service';
import { SurveyFilters } from '../survey/survey.model';
import { SurveyService } from '../survey/survey.service';
import { ErrorStatusService } from 'src/app/_shared/_services/error-status.service';
import { DomSanitizer } from '@angular/platform-browser';
import { QuestCheckMark } from './questionnaire.model';
import { UserCompanyService } from 'src/app/user-company/user-company.service';
import { style } from '@angular/animations';
import { PostService } from 'src/app/settings/post/posts.service';
import { SharedFilterService } from 'src/app/_shared/_services/shared-filter.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';


@Component({
    selector: 'app-questionnaire',
    templateUrl: './questionnaire.component.html',
    styleUrls: ['./questionnaire.component.scss']
})

export class QuestionnaireComponent {

    selectedTab: string;

    @ViewChild(TableComponent) tableComponent: TableComponent;

    private destroy$ = new Subject<void>();


    onMassSend = async (items) => {
        try {
            var hasActiveEmail = await this.companyService.checkEmailConfig().toPromise()
        } catch (err) {
            if (err.error && err.error.code == 420) {
                var message = 'Renueva tu licencia para poder realizar esta acción.';
                var errorData = {
                    name: 'Error',
                    message: message,
                    group: null,
                    template: null,
                    noCancel: true
                }

                this.loaderService.hide()
                const eRef = this.dialog.open(
                    FormDialogComponent,
                    {
                        data: errorData,
                    }
                );

                await eRef.afterClosed().toPromise();
                return
            }

            var optionData = {
                name: 'Enviar correos',
                message: 'Parece que actualmente no cuenta con un correo configurado para el envió de correo, lo invitamos a que configure uno en la sección de Cuenta>Configuración de correo electrónico. ' +
                    'Esto ayudara a que sus empleados reconozcan rápidamente los correos de su organización.',
                buttonOptions: [
                    {
                        placeholder: 'Cancelar envio',
                        type: 'warn',
                        value: 0
                    },
                    {
                        placeholder: 'Ir a configurar',
                        type: 'primary',
                        value: 1
                    }
                ]
            }

            var option = await this.dialog.open(FormDialogComponent, { data: optionData, panelClass: 'panel-nooverflow' }).beforeClosed().toPromise()

            if (!option) {
                this.tableComponent.cleanChecks();
                return
            }

            switch (option) {
                case 0:
                    return
                    break;
                case 1:
                    this.route.navigate(['/users/account'], {
                        queryParams: {
                            config: true
                        }
                    })
                    return;
                    break;
                case 2:
                    break;
            }
        }


        try {
            /* var selected: any = this.tableComponent.selection.selected;

            Object.keys(selected).map(k => {
                if (selected[k].solved) {
                    delete selected[k];
                }
            }); */

            var selected = this.getSelectedItems(false)

            if (Object.entries(selected).length === 0) {

                var selectionData = {
                    name: 'Enviar correos',
                    message: 'Debes seleccionar al menos un empleado para enviar correos',
                    group: null,
                    template: null,
                    noCancel: true
                }
                const selectionRef = this.dialog.open(
                    FormDialogComponent,
                    {
                        data: selectionData,
                    }
                );

                await selectionRef.afterClosed().toPromise()

                return;
            }



            this.loaderService.show()

            var settings: QuestionnaireToSend = {
                periodID: this.filters.PeriodID,
                surveyID: this.filters.SurveyID,
                applyTo: Object.keys(selected) as any
            }

            var res = await this.questionnaireService.sendEmail(settings, true).toPromise()

            this.loaderService.hide()

            var successData = {
                name: 'Enviar correos',
                message: this.onSendMessage,
                group: null,
                template: null,
                noCancel: true,
                isAlertMessage: true
            }
            const sRef = this.dialog.open(
                FormDialogComponent,
                {
                    data: successData,
                }
            );

            await sRef.afterClosed().toPromise();

            this.loadEmployeesToSend();

        } catch (err) {

            if (!this.errorService.getError()) {
                var message = 'Ocurrio un error al enviar los correos';
                if (err.error && err.error.code == 420) {
                    message = 'Renueva tu licencia para poder realizar esta acción.';
                }
                var errorData = {
                    name: 'Error',
                    message: message,
                    group: null,
                    template: null,
                    noCancel: true
                }

                this.loaderService.hide()
                const eRef = this.dialog.open(
                    FormDialogComponent,
                    {
                        data: errorData,
                    }
                );

                await eRef.afterClosed().toPromise();

                this.loaderService.show()
            }
        }
        finally {
            this.tableComponent.cleanChecks();
            this.loaderService.hide();
        }
    }

    downloadFormat = async (items) => {

        try {
            var selected = this.getSelectedItems(true);

            if (Object.entries(selected).length === 0) {

                var selectionData = {
                    name: 'Carga masiva de resultados',
                    message: 'Debes seleccionar al menos un empleado para descargar el formato.',
                    group: null,
                    template: null,
                    noCancel: true
                }
                const selectionRef = this.dialog.open(
                    FormDialogComponent,
                    {
                        data: selectionData,
                    }
                );

                await selectionRef.afterClosed().toPromise()

                return;
            }

            this.loaderService.show()


            var periodID = this.filters.PeriodID
            var surveyID = this.filters.SurveyID
            var applyTo = Object.keys(selected).map(k => parseInt(k)) as any

            var res = await this.questionnaireService.downloadFormat(surveyID, periodID, applyTo).toPromise()
            console.log(res);
            let blob = new Blob([res]);
            var link = window.document.createElement('a');
            link.href = window.URL.createObjectURL(blob);

            // Construct filename dynamically and set to link.download
            link.download = link.href.split('/').pop() + '.' + 'xlsx';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            this.loaderService.hide()

        } catch (err) {
            this.loaderService.hide()
            if (!this.errorService.getError()) {
                var message = 'Ocurrió un error al intentar descargar el formato.';
                var errorData = {
                    name: "Error",
                    message: message,
                    noCancel: true
                }
                var eref = this.dialog.open(FormDialogComponent, { data: errorData });
                var res = await eref.afterClosed().toPromise();
            }
        }
        this.tableComponent.cleanChecks();
    }

    uploadFormat = async () => {
        this.uploader.nativeElement.value = null;
        this.uploader.nativeElement.click();
    }

    async uploadFile(event) {

        if (!event)
            return

        var file = event.target.files[0];

        var form = new QuestCheckMark
        const data = {
            name: 'Carga masiva de resultados',
            message: '',
            group: form.initNewForm(form)
        };

        var flags = await this.dialog.open(FormDialogComponent, { data: data, panelClass: 'panel-nooverflow', width: '500px' })
            .afterClosed().toPromise()

        if (!flags) {
            return;
        }

        this.loaderService.show()
        flags = flags.getRawValue()

        const formData = new FormData();
        formData.append('files', file);

        try {

            var res = await this.questionnaireService.uploadResults(this.surveysFilter.filterByType, this.filters.PeriodID, flags.questCheck, formData).toPromise();

            if (!res || res.length == 0) {
                var successData = {
                    name: 'Carga masiva de resultados',
                    message: 'Formato cargado correctamente',
                    group: null,
                    noCancel: true
                }

                this.loaderService.hide();

                const sRef = this.dialog.open(
                    FormDialogComponent,
                    {
                        data: successData,
                    }
                );

                await sRef.afterClosed().toPromise();
            } else if (res.errors) {
                var errorData = {
                    name: 'Carga masiva de resultados',
                    message: 'No se pudo cargar el formato, ya que contiene los siguientes errores:',
                    group: null,
                    noCancel: true,
                    errors: res.errors
                }

                this.loaderService.hide();

                const eRef = this.dialog.open(
                    FormDialogComponent,
                    {
                        data: errorData,
                    }
                );

                await eRef.afterClosed().toPromise();

                const byteArray = new Uint8Array(
                    atob(res.excel.fileContents).split('').map(char => char.charCodeAt(0))
                );
                var blob = new Blob([byteArray], { type: 'application/pdf' });

                var link = window.document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                // Construct filename dynamically and set to link.download
                link.download = link.href.split('/').pop() + '.' + 'xlsx';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);

            }
        } catch (err) {

            var eData = {
                name: 'Error',
                message: 'Ocurrio un error al tratar de subir el formato',
                group: null,
                noCancel: true
            }

            this.loaderService.hide();

            const erRef = this.dialog.open(
                FormDialogComponent,
                {
                    data: eData,
                    panelClass: 'panel-nooverflow'
                }
            );

            await erRef.afterClosed().toPromise();

        } finally {
            this.uploader.nativeElement.value = "";
            this.loaderService.hide();
            this.filterData();
        }
    }

    title: string;

    description: string;

    paging: PagingParams = new PagingParams();

    periods = []

    workCenters = []

    departments = []

    surveys = []

    areas = []

    posts = []

    surveysFilter = new SurveyFilters()

    rows = []

    tableConfig: TableConfig = {
        canResetHeaders: true,
        actions: [
            {
                icon: 'send',
                title: 'Enviar correos',
                color: 'blue',
                action: this.onMassSend
            },
            {
                icon: 'cloud_download',
                title: 'Descargar resultados',
                color: 'red',
                action: async () => {
                    /* if (!this.sessionService.checkClaims(2)) {
                        this.fileError()
                        return
                    } */

                    var noSolved = false;

                    var solved: any = this.rows.filter((r) => r.solved && r.isSelected)

                    if (solved.length == 0) {

                        var selectionData = {
                            name: 'Descargar resultados',
                            message: 'Debes seleccionar al menos un empleado para descargar resultados',
                            group: null,
                            template: null,
                            noCancel: true
                        }
                        const selectionRef = this.dialog.open(
                            FormDialogComponent,
                            {
                                data: selectionData,
                            }
                        );

                        await selectionRef.afterClosed().toPromise()
                        this.tableComponent.cleanChecks();
                        return;
                    }

                    this.loaderService.show();

                    //PDF o ZIP
                    var optionsData = {
                        name: "Formato de resultados",
                        message: "Eliga la opción deseada",
                        buttonOptions: [
                            {
                                placeholder: 'Un solo PDF',
                                type: 'warn',
                                value: 1
                            },
                            {
                                placeholder: 'Archivo comprimido (varios PDF)',
                                type: 'primary',
                                value: 2
                            }
                        ]
                    }
                    this.loaderService.hide()

                    var option = await this.dialog.open(FormDialogComponent, { data: optionsData, panelClass: 'panel-nooverflow' }).beforeClosed().toPromise();

                    if (!option) {
                        this.tableComponent.cleanChecks();
                        return
                    }

                    this.loaderService.show();

                    var licenseError = false;

                    var promises: any = Array.from(solved).map((row: any) => {
                        return this.questionnaireService
                            .getAnswers(this.surveysFilter.filterByType, row.quessionaireID)
                            .toPromise()
                            .then((res) => {
                                return { blob: res, name: row.employee.name + row.employee.lastName + row.employee.motherLastName }
                            })
                            .catch((err) => {
                                if (err.error && err.error.code == 420) {
                                    licenseError = true
                                }
                            })

                    });

                    if (licenseError) {
                        var errorData = {
                            name: 'Error',
                            message: 'No puede realizar operaciones hasta que actualice su licencia',
                            group: null,
                            template: null,
                            noCancel: true
                        }

                        this.loaderService.hide()
                        const eRef = this.dialog.open(
                            FormDialogComponent,
                            {
                                data: errorData,
                            }
                        );

                        this.tableComponent.cleanChecks();
                        await eRef.afterClosed().toPromise();
                        return;
                    }

                    //se obtienen todos los archivos
                    var files: any = await Promise.all(promises);

                    if (option == 1) //Todo en un solo pdf
                    {
                        var pdfDoc = await PDFDocument.create()

                        for (let i = 0; i < files.length; i++) {
                            if (files[i] && files[i].blob) {
                                var toBuf: any = new Response(files[i].blob)
                                toBuf = await toBuf.arrayBuffer()

                                //Tomar paginas de pdf
                                var page = null;
                                var aux = null;
                                var auxPdf = await PDFDocument.load(toBuf)

                                for (var j = 0; j < auxPdf.getPageCount(); j++) {
                                    aux = await pdfDoc.copyPages(auxPdf, [j])
                                    page = pdfDoc.addPage(aux[0])
                                }
                            }
                        }

                        await pdfDoc.save().then((result) => {
                            let blob = new Blob([result]);
                            var link = window.document.createElement('a');
                            link.href = window.URL.createObjectURL(blob);
                            // Construct filename dynamically and set to link.download
                            link.download = this.title.replace(/\s/g, '_') + this.periods.find(p => p.periodID.value == this.filters.PeriodID).name.value + '.' + 'pdf';
                            document.body.appendChild(link);
                            link.click();
                            document.body.removeChild(link);
                        })

                    } else { //Archivo comprimido
                        var zip = new JSZip();

                        for (var i = 0; i < files.length; i++) {
                            if (files[i] && files[i].blob)
                                zip.file(files[i].name + '.pdf', files[i].blob);
                        }

                        var zipName = "Resultados" + this.title.replace(/\s/g, '') + this.periods.find(p => p.periodID.value == this.filters.PeriodID).name.value;

                        zip.generateAsync({ type: "blob" })
                            .then(function (content) {
                                saveAs(content, zipName + "_resultados.zip");
                            });
                    }




                    this.tableComponent.cleanChecks();
                    this.loaderService.hide();
                },
                isRead: true
            },
            {
                icon: 'groups',
                title: "Cuestionario masivo",
                color: 'blue',
                action: async () => {
                    let values = this.form.getRawValue()
                    let res = await this.questionnaireService.getMassiveClientLink(values.period, values.survey)
                        .toPromise()
                    console.log(res)

                    var cb: any = document.getElementById("cb");
                    cb.value = res.value;
                    cb.style.display = 'block';
                    cb.select();
                    document.execCommand('copy');
                    cb.style.display = 'none';

                    var data = {
                        name: "Link para cuestionarios",
                        message: 'El link ha sido copiado al portapapeles',
                        /* extraMessages: [
                            {
                                value: res.value,
                                placeholder: "Link masivo",
                                isUrl: true
                            }
                        ], */
                        noCancel: true
                    }

                    await this.dialog.open(FormDialogComponent, { data: data }).afterClosed().toPromise()

                },
            },
            {
                icon: 'cloud_download',
                title: 'Descargar Formato',
                color: 'red',
                action: this.downloadFormat,
                isRead: true
            },
            {
                icon: 'cloud_upload',
                title: 'Subir Resultados',
                color: 'green',
                action: this.uploadFormat,
            },
            {
                icon: 'forward_to_inbox',
                title: 'Enviar política',
                color: 'green',
                action: async () => {
                    var optionsData = {
                        name: "Enviar Política de Prevención",
                        message: "Eliga la opción deseada",
                        buttonOptions: [
                            {
                                placeholder: 'Enviar vía email',
                                type: 'warn',
                                value: 1
                            },
                            {
                                placeholder: 'Copiar a portapapeles',
                                type: 'primary',
                                value: 2
                            }
                        ]
                    }
                    var option = await this.dialog.open(FormDialogComponent, { data: optionsData, panelClass: 'panel-nooverflow' }).beforeClosed().toPromise();
                    this.loaderService.show()
                    try{
                        if (!option) return
                        else if (option == 1) {
                            let res : any = await this.companyService.getPreventionPolicy(true).toPromise()
                            let successData = {
                                name: "Enviar Política de Prevención",
                                message: 'Política enviada correctamente',
                                noCancel: true
                            }
                            this.dialog.open(FormDialogComponent, {data : successData})
                        } else {
                            let res : any = await this.companyService.getPreventionPolicy(false).toPromise()
                            let cb: any = document.getElementById("cb");
                            cb.value = res.value;
                            cb.style.display = 'block';
                            cb.select();
                            document.execCommand('copy');
                            cb.style.display = 'none';
    
                            let successData = {
                                name: "Link para cuestionarios",
                                message: 'El link ha sido copiado al portapapeles',
                                noCancel: true
                            }
                            this.dialog.open(FormDialogComponent, {data : successData})
                        }
                    }catch(ex){
                        console.error(ex)
                    }finally{                        
                        this.loaderService.hide()
                    }


                }
            }
        ],
        headers: [
            {
                displayName: "Código",
                property: "employee.employeeCode",
                showHeader: false,
                size: '150px',
                order: 'EmployeeCode',
                search: 'FilterByCode',
                searchValue: ''
            },
            {
                displayName: "Nombre",
                property: "employee.name",
                showHeader: true,
                size: '150px',
                order: 'Name',
                search: 'FilterByName',
                searchValue: ''
            }, {
                displayName: "Apellido Paterno",
                property: "employee.lastName",
                showHeader: true,
                size: '180px',
                order: 'LastName',
                search: 'FilterByFLastName',
                searchValue: ''
            }, {
                displayName: "Apellido Materno",
                property: "employee.motherLastName",
                showHeader: true,
                size: '180px',
                order: 'MotherLastName',
                search: 'FilterByMLastName',
                searchValue: ''
            },
            {
                displayName: "Género",
                property: "employee.gender",
                showHeader: false,
                size: '150px',
                enum: { true: 'Masculino', false: 'Femenino' },
                order: 'Gender',
                search: 'FilterByGender',
                searchOptions: [[true, 'Masculino'], [false, 'Femenino']],
                searchValue: ''
            },
            {
                displayName: "CURP",
                property: "employee.curp",
                showHeader: false,
                size: '200px',
                order: 'Curp',
                search: 'FilterByCurp',
                searchValue: ''
            },
            {
                displayName: "RFC",
                property: "employee.rfc",
                showHeader: false,
                size: '200px',
                order: 'Rfc',
                search: 'FilterByRfc',
                searchValue: ''
            },
            {
                displayName: "Número de Seguridad Social",
                property: "employee.socialSecurityNumber",
                showHeader: false,
                size: '250px',
                //order: 'SocialSecurityNumber',
                search: 'FilterBySocialSecurityNumber',
                searchValue: '',
                hasFormat: true,
                format: (value: string) => {
                    if (!value || value == "")
                        return "";

                    var newValue = []
                    newValue = [].concat(value.slice(0, 2), '-', value.slice(2, 4), '-', value.slice(4, 6), '-', value.slice(6, 10), '-', value.slice(10, value.length));

                    var aux = newValue.join('');

                    return aux;
                }
            },
            {
                displayName: "Email",
                property: "employee.email",
                showHeader: true,
                size: '350px',
                order: 'Email',
                search: 'FilterByEmail',
                searchValue: ''
            },
            {
                displayName: "Puesto",
                property: "employee.post.name",
                showHeader: false,
                size: '150px',
                order: 'Post',
                search: 'PostID',
                searchValue: ''
            },
            {
                displayName: "Departamento",
                property: "employee.department.name",
                showHeader: false,
                size: '150px',
                order: 'Department',
                search: 'Departmment',
                searchValue: ''
            },
            {
                displayName: "Área",
                property: "employee.area.name",
                showHeader: false,
                size: '150px',
                order: 'Area',
                search: 'FilterByArea',
                searchValue: ''
            },
            {
                displayName: "Centro Trabajo",
                property: "employee.workCenter.name",
                showHeader: false,
                size: '150px',
                order: 'WorkCenter',
                search: 'WorkCenter',
                searchValue: ''
            },
            {
                displayName: "Estado Civil",
                property: "employee.maritalStatus",
                showHeader: false,
                size: '150px',
                enum: { 0: 'No definido', 1: 'Soltero/a', 2: 'Casado/a', 3: 'Divorciado/a', 4: 'Viudo/a', 5: 'Unión libre', 6: 'Separado/a' },
                //order: 'MaritalStatus',
                search: 'FilterByMaritalStatus',
                searchOptions: [[0, 'No definido'], [1, 'Soltero/a'], [2, 'Casado/a'], [3, 'Divorciado/a'], [4, 'Viudo/a'], [5, 'Unión libre'], [6, 'Separado/a']],
                searchValue: ''
            },
            {
                displayName: "Nivel de Estudios",
                property: "employee.academicLevel",
                showHeader: false,
                size: '150px',
                enum: { 0: 'No definido', 1: 'Ninguno', 2: 'Primaria', 3: 'Secundaria', 4: 'Bachillerato', 5: 'Carrera Técnica', 6: 'Licenciatura', 7: 'Maestría', 8: 'Doctorado' },
                //order: 'AcademicLevel',
                search: 'FilterByAcademicLevel',
                searchOptions: [[0, 'No definido'], [1, 'Ninguno'], [2, 'Primaria'], [3, 'Secundaria'], [4, 'Bachillerato'], [5, 'Carrera Técnica'], [6, 'Licenciatura'], [7, 'Maestría'], [8, 'Doctorado']],
                searchValue: ''
            },
            {
                displayName: "Tipo de Jornada de Trabajo",
                property: "employee.shiftType",
                showHeader: false,
                size: '150px',
                enum: { 0: 'No definido', 1: 'Diurna', 2: 'Nocturna', 3: 'Mixta' },
                //order: 'ShiftType',
                search: 'FilterByShiftType',
                searchOptions: [[0, 'No definido'], [1, 'Diurna'], [2, 'Nocturna'], [3, 'Mixta']],
                searchValue: ''
            },
            {
                displayName: "Rotación de Turnos",
                property: "employee.shiftRotation",
                showHeader: false,
                size: '150px',
                enum: { 0: 'No definido', 1: 'Si', 2: 'No' },
                //order: 'ShiftRotation',
                search: 'FilterByShiftRotation',
                searchOptions: [[0, 'No definido'], [1, 'Si'], [2, 'No']],
                searchValue: ''
            },
            {
                displayName: "Estado",
                property: "status",
                showHeader: true,
                size: '150px',
                order: 'Status',
                //enum: { 0: 'No enviado', 1: 'Enviado', 2: 'Contestado' },
                enum: { 0: 'No asignado', 1: 'Asignado', 2: 'Evaluado' },

                search: 'FilterByStatus',
                searchOptions: [[0, 'No asignado'], [1, 'Asignado'], [2, 'Evaluado']],
                searchValue: ''
            },
            {
                displayName: "Resultados",
                property: "seeResult",
                showHeader: true,
                size: '125px',
                action: async (row) => {
                    this.showPdf(row);
                    /* this.loaderService.show()

                    var blob: any = await this.questionnaireService.getAnswers(this.surveysFilter.filterByType, row.quessionaireID).toPromise()

                    var link = window.document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    // Construct filename dynamically and set to link.download

                    var type = ""
                    switch (this.surveysFilter.filterByType) {
                        case 1:
                            type = "ATS"
                            break;
                        case 2:
                            type = "RP"
                            break;
                        case 3:
                            type = "EO"
                            break;
                    }

                    link.download = row.employee.name + row.employee.lastName + row.employee.motherLastName + "_" + type + '.' + 'pdf';
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link); */
                },
                actionName: '',
                icon: 'ver',
                isSvg: true,
                isRead: true
            },
            {
                displayName: "Contestar",
                property: "applyTest",
                showHeader: true,
                size: '150px',
                action: async (row) => {
                    try {
                        var employeeID = row.employeeID;

                        var settings: QuestionnaireToSend = {

                            periodID: this.filters.PeriodID,
                            surveyID: this.filters.SurveyID,
                            applyTo: [employeeID]
                        }

                        var res = await this.questionnaireService.sendEmail(settings, false).toPromise()

                        window.open(res[0], "_blank");
                    } catch (err) {

                        if (!this.errorService.getError()) {
                            var message = 'Ocurrio un error al abrir el cuestionario';
                            if (err.error && err.error.code == 420) {
                                var message = 'Renueva tu licencia para poder realizar esta acción.';
                            }
                            var errorData = {
                                name: 'Error',
                                message: message,
                                group: null,
                                template: null,
                                noCancel: true
                            }
                            this.loaderService.hide()
                            const eRef = this.dialog.open(
                                FormDialogComponent,
                                {
                                    data: errorData,
                                }
                            );

                            await eRef.afterClosed().toPromise()
                        }
                    }
                    finally {
                        this.loaderService.hide();
                    }
                },
                actionName: 'Contestar',
                icon: 'contestar',
                isSvg: true

            },
            {
                displayName: "Copiar Link",
                property: 'copyLink',
                showHeader: true,
                size: '150px',
                actionName: 'Copiar',
                icon: 'file_copy',
                isSvg: false,
                action: async (row) => {
                    try {
                        var employeeID = row.employeeID;

                        this.loaderService.show()

                        var settings: QuestionnaireToSend = {

                            periodID: this.filters.PeriodID,
                            surveyID: this.filters.SurveyID,
                            applyTo: [employeeID]
                        }

                        var res = await this.questionnaireService.sendEmail(settings, false).toPromise()


                        var cb: any = document.getElementById("cb");
                        cb.value = res[0];
                        cb.style.display = 'block';
                        cb.select();
                        document.execCommand('copy');
                        cb.style.display = 'none';


                        this.loaderService.hide();
                        this.snackBar.open('Se ha copiado el link en tu portapapeles.')
                    } catch (err) {
                        if (!this.errorService.getError()) {
                            var message = 'Ocurrio un error al copiar el link del cuestionario';
                            if (err.error && err.error.code == 420) {
                                var message = 'Renueva tu licencia para poder realizar esta acción.';
                            }
                            var errorData = {
                                name: 'Error',
                                message: message,
                                group: null,
                                template: null,
                                noCancel: true
                            }
                            this.loaderService.hide()
                            const eRef = this.dialog.open(
                                FormDialogComponent,
                                {
                                    data: errorData,
                                }
                            );

                            await eRef.afterClosed().toPromise()
                            this.loaderService.show()
                        }
                    }
                    finally {
                        this.loaderService.hide();
                        this.loadEmployeesToSend();
                    }
                }
            },
            {
                displayName: "Enviar",
                property: "sendTest",
                showHeader: true,
                size: '150px',
                action: async (row) => {
                    try {
                        var hasActiveEmail = await this.companyService.checkEmailConfig().toPromise()
                    } catch (err) {

                        if (err.error && err.error.code == 420) {
                            var message = 'Renueva tu licencia para poder realizar esta acción.';
                            var errorData = {
                                name: 'Error',
                                message: message,
                                group: null,
                                template: null,
                                noCancel: true
                            }

                            this.loaderService.hide()
                            const eRef = this.dialog.open(
                                FormDialogComponent,
                                {
                                    data: errorData,
                                }
                            );

                            await eRef.afterClosed().toPromise();
                            return
                        }

                        var optionData = {
                            name: 'Enviar',
                            message: 'Parece que actualmente no cuenta con un correo configurado para el envió de correo, lo invitamos a que configure uno en la sección de Cuenta>Configuración de correo electrónico. ' +
                                'Esto ayudara a que sus empleados reconozcan rápidamente los correos de su organización.',
                            buttonOptions: [
                                {
                                    placeholder: 'Cancelar envio',
                                    type: 'warn',
                                    value: 0
                                },
                                {
                                    placeholder: 'Ir a configurar',
                                    type: 'primary',
                                    value: 1
                                },
                            ]
                        }

                        var option = await this.dialog.open(FormDialogComponent, { data: optionData, panelClass: 'panel-nooverflow' }).beforeClosed().toPromise()

                        if (!option) {
                            return
                        }

                        switch (option) {
                            case 0:
                                return
                                break;
                            case 1:
                                this.route.navigate(['/users/account'], {
                                    queryParams: {
                                        config: true
                                    }
                                })
                                return;
                                break;
                            case 2:
                                break;
                        }
                    }

                    try {
                        var employeeID = row.employeeID;

                        this.loaderService.show()

                        var settings: QuestionnaireToSend = {
                            periodID: this.filters.PeriodID,
                            surveyID: this.filters.SurveyID,
                            applyTo: [employeeID]
                        }

                        var res = await this.questionnaireService.sendEmail(settings, true).toPromise()

                        var successData = {
                            name: '',
                            message: 'Solicitud de envío de cuestionarios en proceso',
                            extraMessages: [
                                {
                                    value: "verifica tu bandeja de correos enviados."
                                }
                            ],
                            group: null,
                            template: null,
                            noCancel: true,
                            isAlertMessage: true,
                            sendMailAlertMessage: true
                        }


                        this.loaderService.hide()
                        const sRef = this.dialog.open(
                            FormDialogComponent,
                            {
                                data: successData,
                            }
                        );

                        await sRef.afterClosed().toPromise()
                        this.loaderService.show()

                    } catch (err) {
                        if (!this.errorService.getError()) {
                            var message = "Ocurrio un error al enviar los cuestionario";
                            if (err.error && err.error.code == 420) {
                                var message =
                                    "Renueva tu licencia para poder realizar esta acción.";
                            }
                            var errorData = {
                                name: "Error",
                                message: message,
                                group: null,
                                template: null,
                                noCancel: true,
                            };
                            this.loaderService.hide();
                            const eRef = this.dialog.open(FormDialogComponent, {
                                data: errorData,
                            });

                            await eRef.afterClosed().toPromise();
                            this.loaderService.show();
                        }

                    }
                    finally {
                        this.loaderService.hide();
                        this.loadEmployeesToSend();
                    }
                },
                actionName: 'Enviar',
                icon: 'enviar',
                isSvg: true
            }
        ],
        showEdit: false,
        showAnswer: false,
        showSend: false,
        isSelectable: true,
    };

    applicator

    onSendSuccessMessage = "Correo enviado con éxito";

    onSendMessage = "Solicitud de envío de cuestionarios en proceso. Verifica tu bandeja de correos enviados.";

    onSendErrorMessage = "Ocurrio un error al enviar";

    onAnswerSuccessMessage = "Cuestionario generado con éxito";

    onAnswerErrorMessage = "Ocurrio un error al generar cuestionario";


    filters = new QuestionnaireEmployeesFilters()

    form: UntypedFormGroup = new UntypedFormGroup({
        period: new UntypedFormControl(),
        workCenter: new UntypedFormControl(0),
        department: new UntypedFormControl(0),
        area: new UntypedFormControl(0),
        post: new UntypedFormControl(0),
        survey: new UntypedFormControl()
    })

    orderBy: OrderBy[] = []

    sectionName = "Cuestionarios "

    initFilters: any = {}
    
    selectedPeriod: any;

    @ViewChild('uploader', { static: true }) uploader: ElementRef;

    constructor(
        protected loaderService: LoaderService,
        protected activatedRoute: ActivatedRoute,
        protected questionnaireService: QuestionnaireService,
        protected sessionService: SessionService,
        protected cdr: ChangeDetectorRef,
        protected dialog: MatDialog,
        protected snackBar: MatSnackBar,
        protected route: Router,
        protected injector: Injector,
        protected errorService: ErrorStatusService,
        private periodService: PeriodService,
        private workCenterService: WorkCenterService,
        private departmentService: DepartmentService,
        private areaService: AreaService,
        private surveyService: SurveyService,
        private companyService: CompanyService,
        private postService: PostService,
        private userCompanyService: UserCompanyService,
        private sanitizer: DomSanitizer,
        private sharedFilterService: SharedFilterService,

    ) {
        this.activatedRoute.queryParams.subscribe((res) => {
            if (res && res.periodID) {
                console.log(res)
                this.initFilters = res
            }
        }).unsubscribe()
    }

    ngOnInit() {
        this.loaderService.show()

        this.sharedFilterService.filterData$.pipe(takeUntil(this.destroy$)).subscribe(filterData => {
            if (filterData && filterData.period) {
              this.form.get('period').setValue(filterData.period);
              this.filterData();
            }
          });

        this.sharedFilterService.filterData$.pipe(takeUntil(this.destroy$)).subscribe((periods) => {
        this.periods = periods; // Actualiza la lista localmente
        //console.log('Lista de periodos actualizada:', this.periods);
        });

        this.sharedFilterService.selectedPeriod$.pipe(takeUntil(this.destroy$)).subscribe((period) => {
            this.selectedPeriod = period;
            //console.log('Periodo seleccionado:', this.selectedPeriod);
          });

        this.activatedRoute.params.subscribe((guide) => {
            switch (guide.id) {
                case 'ats':
                    this.sessionService.setNavState(8);
                    this.title = 'Guia I: Acontecimientos Traumáticos Severos';
                    this.surveysFilter.filterByType = 1;
                    this.description = "Acontecimientos Traumáticos Severos"
                    this.sectionName = 'Cuestionarios ATS';
                    break;
                case 'rp':
                    this.sessionService.setNavState(10);
                    this.title = 'Guia II: Riesgo Psicosocial';
                    this.surveysFilter.filterByType = 2;
                    this.description = "Factores de Riesgo Psicosocial"
                    this.sectionName = 'Cuestionarios RP';
                    break;
                case 'eo':
                    this.sessionService.setNavState(12);
                    this.title = 'Guia III: Entorno Organizacional';
                    this.surveysFilter.filterByType = 3;
                    this.description = "Entorno Organizacional"
                    this.sectionName = 'Cuestionarios EO';
                    break;
            }

        this.route.events.subscribe(event => {
            if (event instanceof NavigationEnd && this.route.url === '/home') {
                //this.reloadData(); // Se ejecuta al regresar a esta ruta
            }
            });


            this.getAll()
                .finally(() => {
                    this.loaderService.hide()
                })

        })
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete(); // Completa el Subject para liberar memoria
      }

    reloadData(){
        //this.loaderService.show()

            this.getAll()
                .finally(() => {
                    //this.loaderService.hide()
                })

    }

    getAll() {

        return Promise.all(
            [
                this.periodService.getAllSelect().toPromise(),
                this.workCenterService.getAllSelect().toPromise(),
                this.departmentService.getAllSelect().toPromise(),
                this.surveyService.getAll(null, this.surveysFilter).toPromise(),
                this.areaService.getAllSelect().toPromise(),
                this.postService.getAllSelect().toPromise(),
                this.userCompanyService.getApplicator().toPromise()
            ]
        )
            .then((result) => {


                this.periods = result[0].items;
                this.workCenters = result[1].items;
                this.departments = result[2].items;
                this.surveys = result[3].items;
                this.areas = result[4].items;
                this.posts = result[5].items;

                this.tableConfig.headers.find(x => x.property == "employee.area.name").searchOptions = this.areas.map(a => [a.areaID.value, a.name.value]);
                this.tableConfig.headers.find(x => x.property == "employee.department.name").searchOptions = this.departments.map(a => [a.departmentID.value, a.name.value]);
                this.tableConfig.headers.find(x => x.property == "employee.post.name").searchOptions = this.posts.map(a => [a.postID.value, a.name.value]);
                this.tableConfig.headers.find(x => x.property == "employee.workCenter.name").searchOptions = this.workCenters.map(a => [a.workCenterID.value, a.name.value]);

                this.applicator = result[6];

                this.tableComponent.config.extra =
                {
                    content:
                        '<div style="width: 100%; border: 1px solid; border-color: var(--primary-color);' +
                        'border-radius: 5px; padding: 6px; position: relative; cursor: pointer;">' +
                        '<h4 style="position: absolute; margin: 0;top: -8px;right: 10px; background-color: white;">Aplicador</h4>' +
                        '<h4 style="margin: 0;">Nombre</h4>' +
                        `<span matTooltip="Info about the action">${(this.applicator.applicatorName) ? this.applicator.applicatorName : 'Sin definir'}</span>` +
                        '<h4 style="margin: 0;">Cédula Profesional</h4>' +
                        `<span>${(this.applicator.applicatorProfessionalLicense) ? this.applicator.applicatorProfessionalLicense : 'Sin definir'}</span>` +
                        '</div>',
                    link: {
                        route: 'users/account',
                        params: 1,
                        tooltip: 'Ir a Actualizar Aplicador'
                    },
                    style: 'font-size: 12px;'
                };

                const onReloadCurrentUrl = this.route.url; // Obtiene la URL completa actual

                if (this.periods.length > 0) { //Get the last one
                    /*this.sharedFilterService.filterData$.subscribe(filterData => {
                        if (filterData && filterData.period) {
                          this.form.get('period').setValue(filterData.period);
                          this.sharedFilterService.updateFilter(filterData.period);
                        } else {
                            this.form.get('period').setValue(this.periods[this.periods.length - 1]._periodID);
                            try {
                                this.sharedFilterService.updateFilter(this.form.get('period').value);
                            } catch (error) {
                                if (error instanceof RangeError) {
                                } else {
                                    throw error; // Lanza otros errores para no ocultarlos
                                }
                            }
                        }
                      });*/
                      this.sharedFilterService.selectedPeriod$.pipe(takeUntil(this.destroy$)).subscribe((period) => {
                        if (period) {
                            this.selectedPeriod = period;
                            //console.log('Periodo seleccionado:', this.selectedPeriod);
                            this.form.get('period').setValue(period._periodID);
                        } else {
                            this.form.get('period').setValue(this.periods[this.periods.length - 1]._periodID);
                            this.sharedFilterService.updateFilter(this.form.get('period').value);
                        }
                      });
                } else if (onReloadCurrentUrl.includes('questionnaire')) {
                    this.periods = null;
                    this.sendToPeriodForm();
                    return;
                }
                if (this.surveys.length > 0)
                    this.form.get('survey').setValue(this.surveys[0]._surveyID);

                if (this.initFilters && this.initFilters.periodID) {


                    this.form.get('period').setValue(parseInt(this.initFilters.periodID));

                    let index = this.tableConfig.headers.findIndex(h => h.displayName == "Estado")
                    if (this.initFilters.employeeFilter) {

                        this.tableConfig.headers[index].searchValue = this.initFilters.employeeFilter
                        this.filters.FilterByStatus = this.initFilters.employeeFilter
                    }

                }

                return this.filterData();

            })
    }

    async sendToPeriodForm() {
        this.dialog = this.injector.get(MatDialog);

        var messageData = {
            name: 'No hay datos',
            message: 'No se encuentran periodos creados/habilitados para las evaluaciones.',
            extraMessages: [
                {
                    value: "Crea/habilita un periodo para continuar."
                }
            ],
            group: null,
            template: null,
            noCancel: true,
            noAccept: true,
            isAlertMessage: true,
            isAlertMessageWithName: true,
            sendMailAlertMessage: true,
            sendToPeriodFormButton: true
        }
        const mRef = this.dialog.open(
            FormDialogComponent,
            {
                data: messageData,
            }
        );

        await mRef.afterClosed().toPromise()
    }

    loadEmployeesToSend() {
        if (this.tableComponent) {
            this.tableComponent.isAllSelected = false;
        }
        this.loaderService.show();
        let hasError = false; // Variable de control de errores
        return this.questionnaireService
            .getEmployeesToSend(this.paging, this.filters, this.orderBy)
            .toPromise()
            .then((res) => {
                this.rows = res.items.map(x => {
                    if (x.solved) {
                        x.seeResult = true;
                    }
                    else {
                        x.applyTest = true;
                        x.sendTest = true;
                        x.copyLink = true;
                    }
                    return Object.assign(new Questionnaire(), x);
                });
                this.paging = res.paging;
            })
            .catch((e) => {
                //this.snackBar.open('Error al cargar los datos')
                hasError = true;
                console.error(e)
            })
            .finally(() => {
                const currentUrl = this.route.url;

                if (currentUrl.includes('questionnaire')) {
                    if (!hasError) {
                        const period = this.sharedFilterService.selectedPeriod;
                        if (!this.rows || this.rows.length === 0 || !period) {
                            //console.log("No tienes registros en esta sección");
                            this.snackBar.open('No tienes registros en esta sección');
                        }
                    }
                }
                this.cdr.markForCheck();
                this.loaderService.hide();
            })
    }

    filterData() {
        var values = this.form.getRawValue()
        if (values.department)
            this.filters.Departmment = values.department as number
        else
            delete this.filters.Departmment;

        if (values.period)
            this.filters.PeriodID = values.period as number
        else
            delete this.filters.PeriodID;

        if (values.workCenter && values.workCenter != 0)
            this.filters.WorkCenter = values.workCenter as number
        else
            delete this.filters.WorkCenter;

        if (values.survey)
            this.filters.SurveyID = values.survey as number
        else
            delete this.filters.SurveyID;

        if (values.area)
            this.filters.FilterByArea = values.area as number
        else
            delete this.filters.FilterByArea;

        if (values.post)
            this.filters.PostID = values.post as number
        else
            delete this.filters.PostID;

        return this.loadEmployeesToSend()
    }

    async fileError() {
        var catchedError = 'No tienes los permisos necesarios para generar archivos.'
        this.errorService.setError(catchedError)

        this.dialog = this.injector.get(MatDialog);

        var messageData = {
            name: "Error",
            message: catchedError,
            noCancel: true
        }

        const dialogRef = this.dialog.open(FormDialogComponent, { data: messageData, panelClass: 'panel-nooverflow' })

        var res = await dialogRef.afterClosed().toPromise()

        this.errorService.setError(null)
    }

    async showPdf(row) {

        var type = ""
        switch (this.surveysFilter.filterByType) {
            case 1:
                type = "ATS"
                break;
            case 2:
                type = "RP"
                break;
            case 3:
                type = "EO"
                break;
        }
        var fileName = row.employee.name + row.employee.lastName + row.employee.motherLastName + "_" + type + '.' + 'pdf';

        try {
            this.loaderService.show()
            var blob: any = await this.questionnaireService.getAnswers(this.surveysFilter.filterByType, row.quessionaireID, true).toPromise()

            var fileName = row.employee.name + row.employee.lastName + row.employee.motherLastName + "_" + type + '.' + 'pdf';
            /* if (blob.value) {
                
                blob.value = this.sanitizer.bypassSecurityTrustHtml(blob.value);
                var htmltData = {
                    name: "Resultado",
                    message: "",
                    fileName: fileName,
                    fileAsHtml: blob.value
                }

                this.loaderService.hide()
                await this.dialog.open(FormDialogComponent, { data: htmltData, panelClass: 'panel-nooverflow', height: '800px', width: '1000px' }).afterClosed().toPromise()

            } else { */
            var toBuf: any = new Response(blob)
            toBuf = await toBuf.arrayBuffer()

            var meblob = new Blob([toBuf], { type: "application/pdf" });
            var blob_url = window.URL.createObjectURL(meblob);

            var san = this.sanitizer.bypassSecurityTrustResourceUrl(blob_url);




            var documentData = {
                name: "Resultado",
                message: "",
                file: san,
                fileName: fileName,
            }

            this.loaderService.hide()
            await this.dialog.open(FormDialogComponent, { data: documentData, panelClass: 'panel-nooverflow', height: '800px', width: '1000px' }).afterClosed().toPromise()



        } catch (err) {
            if (!this.errorService.getError()) {

                var message = 'Ocurrio un error al descargar los resultados';
                if (err.error && err.error.code == 420) {
                    var message = 'Renueva tu licencia para poder realizar esta acción.';
                }
                var errorData = {
                    name: 'Error',
                    message: message,
                    group: null,
                    template: null,
                    noCancel: true
                }
                this.loaderService.hide()
                const eRef = this.dialog.open(
                    FormDialogComponent,
                    {
                        data: errorData,
                    }
                );

                await eRef.afterClosed().toPromise()
                this.loaderService.show()
            }
        }
        finally {
            this.loaderService.hide();
        }
    }


    getSelectedItems(ignoreResult: boolean) {
        var selected: any = this.tableComponent.selection.selected;

        Object.keys(selected).map(k => {
            if (selected[k].solved && !ignoreResult) {
                delete selected[k];
            }
        });

        return selected
    }


}